/**
All supported font style and weights for `Roboto`
 */

@font-face {
  font-family: 'OpenSans';
  src: url(assets/font/OpenSans-Bold.ttf);
  font-style: normal;
  font-weight: bold;
}

@font-face {
  font-family: 'OpenSans';
  src: url(assets/font/OpenSans-RegularItalic.ttf);
  font-style: italic;
  font-weight: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url(assets/font/OpenSans-Regular.ttf);
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: 'OpenSans';
  src: url(assets/font/OpenSans-Light.ttf);
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'OpenSans';
  src: url(assets/font/OpenSans-LightItalic.ttf);
  font-style: italic;
  font-weight: 300;
}
