/* You can add global styles to this file, and also import other style files*/
@import '~material-design-icons/iconfont/material-icons.css';
@import '~@angular/cdk/overlay-prebuilt.css';
@import 'font.css';

*, .mat-typography h1, .mat-typography h2, .mat-typography h3 {
  font-family: 'OpenSans';
}

/* -- Global Settings -- */
*{
  -webkit-box-sizing:   border-box;
  -moz-box-sizing:    border-box;
  box-sizing:       border-box;
  -webkit-font-smoothing: antialiased;
}

/* BACKGROUND SECTION */
.mat-drawer-container {
  background-color: #efefef;
}

.mat-drawer-content.mat-sidenav-content {
  position: relative;
  margin: auto;
  top: 0;
  background-color: #efefef;
  overflow-x: hidden;
}

.restricted-container {
  position: relative;
  margin: auto;
  width: 100%;
  max-width: 1050px;
}

.observation-row {
  cursor: pointer;
}

.observation-row.audit-preview .action-cell{
  max-width: 100px;
}
.observation-row.audit-preview{
  align-items: center;
}
.observation-row.audit-preview .mat-cell{
  min-height: auto;
  line-height: initial;
  top: auto;
}
.observation-row.audit-preview .mat-cell p{
  margin-bottom: 0 !important;
  font-weight: bold;
}

.preview-btn>.mat-button-wrapper {
  position: relative;
  left: -5px;
}

h1.title {
  text-align: center;
  color: #404040;
  margin-top: 10px;
}

.subtitle {
  text-align: center;
  font-weight: 300;
  color: #606060;
}

.help-text {
  position: relative;
  font-weight: 300;
  color: #202020;
  font-size: 13px;
}

.outside-help-text {
  font-weight: 300;
  color: #202020;
  margin-bottom: 5px;
}

.centered-writing {
  text-align: center;
}

.mat-checkbox-label {
  position: relative;
  margin: 10px 10px 10px 5px;
}

.setting-content>.mat-checkbox>.mat-checkbox-layout>.mat-checkbox-label {
  width: 100%;
  text-align: left;
  white-space: normal;
  word-wrap: break-word;
}

.mat-slide-toggle {
  padding-bottom: 20px;
}

.mat-select-panel {
  left: 0;
  max-height: 275px !important;
  min-width: calc(100% + 20px) !important;
}

.mat-snack-bar-container {
  position: relative;
  margin: auto;
  border-radius: 4px !important;
  padding: 0 !important;
  box-shadow: 12px 12px 36px rgba(0, 0, 0, 0.3);

}

.mat-snack-bar-handset {
  max-width: 316px !important;
}

.message-container {
  padding: 10px 14px 0 14px;
  margin-bottom: -20px;
}

.mat-simple-snackbar {
  padding: 15px;
}

.mat-snack-bar-container>meg-custom-snackbar>.message-container>.mat-icon {
  position: relative;
  left: -5px;
  top: 3px;
}

.mat-progress-spinner {
  top: 160px;
}

.mat-progress-spinner.no-space {
  top: 0;
}

.mat-progress-spinner.center {
  margin: auto;
}

.mat-select-value {
  height: 18px;
}

/* CARD SECTION */
.center {
  position: relative;
  margin: auto;
}

mat-card-header {
  text-align: center;
  font-weight: 400;
  font-size: 18px;
  padding-bottom: 20px;
}

div.mat-card-header-text {
  display: none !important;
}

mat-progress-spinner {
  position: relative;
  margin: auto;
}

.mat-bottom-sheet-container{
  min-height: 100px;
}
.mat-bottom-sheet-container .mat-progress-spinner{
  top: 10px;
  margin: auto;
  width:  50px !important;
  height: 50px !important;
}
.mat-bottom-sheet-container .mat-progress-spinner svg{
  width:  50px !important;
  height: 50px !important;
}

.mat-card {
  margin-top: 40px;
  background-color: #fafafa;
  border-top: 1px solid white;
  border-left: 1px solid white;
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

#ward-card {
  margin-top: 10px;
}

.mat-dialog-container {
  position: relative;
  margin: auto;
  background-color: #fafafa;
  border-top: 1px solid white;
  border-left: 1px solid white;
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.3);
  padding: 10px;
  border-radius: 4px;
  max-width: 95%;
  min-width: 270px;
  min-height: 200px;
  max-height: 95%;
  max-height: 90vh;
  overflow: hidden !important;
}

.mat-dialog-content.common-issues-content {
  max-height: 60vh;
}

.capitalise>.mat-option-text {
  text-transform: capitalize;
}

mat-card.mat-card {
  border-radius: 4px !important;
}

mat-card.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.2);
}

.subform-card>.mat-nav-list {
  padding-top: 0;
}

.no-underline>.mat-form-field-wrapper>.mat-form-field-underline {
  display: none;
}

.mat-select-placeholder {
  color: #a0a0a0;
}

.issue-form-field.issue-form-field.mat-input-container.mat-form-field.ng-tns-c18-5.mat-primary.mat-form-field-type-mat-input.mat-form-field-can-float.mat-form-field-hide-placeholder.ng-untouched.ng-pristine.ng-valid {
  width: 100%;
}

.issue-dropdown .mat-select-arrow-wrapper {
  position: relative;
  left: 3px;
}

/* BUTTONS SECTION */
.meg-preview-button-right.preview-btn.mat-raised-button.mat-accent,
.audit-clear-btn.mat-raised-button.mat-warn.ng-star-inserted,
.float-right.right-btn.mat-raised-button.mat-accent {
  margin-bottom: 8px;
}

.mat-raised-button, .mat-button {
  border-radius: 4px !important;
}

#btn-next>.mat-ripple,
#btn-skip>.mat-ripple {
  border-radius: 0;
}

.mat-icon {
  position: relative;
  top: -1px;
}

.button-icon {
  color: rgba(0, 0, 0, 0.7);
}

.mat-datepicker-toggle {
  position: relative;
  top: 2px;
  left: 2px;
  font-size: 24px;
}

.left-btn {
  left: 16px;
  top: 20px;
}

.right-btn {
  right: 16px;
  top: 20px;
}

@media (max-width: 410px) {
  .left-btn {
    left: 6px;
  }

  .right-btn {
    right: 6px;
  }
}

/* BUTTONS ONTAP ANIMATION */
.mat-radio-ripple {
  left: calc(50% - 22px) !important;
  top: calc(50% - 22px) !important;
}

.mat-ripple {
  border-radius: 4px;
}

.meg-list-button>.mat-ripple {
  border-radius: 8px;
}

.mat-select-panel{
  overflow: scroll;
}
.mat-select-panel::-webkit-scrollbar {
  width:12px;
  cursor:pointer;
  background: transparent;
  @include border-radius(3px);
}
.mat-select-panel::-webkit-scrollbar-thumb {
  border: 2px solid #f0f0ed;
  background-clip: content-box;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  background:rgba(0,158,226,1);
  margin:10px;
  cursor:pointer;
}
.mat-select-panel::-webkit-scrollbar-thumb:hover{
  background:rgba(0,158,226,.7);
}
.mat-select-panel::-webkit-scrollbar-thumb:active{
  background:rgba(0,158,226,.5);
}
.mat-select-panel::-webkit-scrollbar-thumb:window-inactive {
  background:rgba(0,158,226,1);
}

.mat-option {
  margin: 2px 0;
  height: min-content !important;
  min-height: 2.6em !important;
}
.mat-select-content .mat-option{
  border-bottom: 1px dashed #efefef;  
}
.mat-select-content .mat-option .mat-option-text{
  max-height: max-content;
}

.mat-nav-list>.mat-list-item>.mat-list-item-content>.mat-ripple {
  border-radius: 0;
  z-index: 2;
}

.three-wrap-ellipsis {
  margin: 5px 0;
  white-space: normal;
  overflow: hidden;
  width: 100%;
  position: relative;
  line-height: 1.2em;
  max-height: 3.7em;
  margin-right: -1em;
  padding-right: 1em;
  text-align: justify;
}

.three-wrap-ellipsis:before {
  content: '…';
  position: absolute;
  right: 6px;
  top: 2.6em;
  margin-top: -3px;
}

.three-wrap-ellipsis:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: #fafafa;
}

.issue-name.three-wrap-ellipsis:before {
  right: 32px;
}

.issue-name.three-wrap-ellipsis:after {
  right: 32px;
}

.issue-name.three-wrap-ellipsis:before, .issue-name.three-wrap-ellipsis:after {
  right: 5px;
}

.mat-list-item:hover>.mat-list-item-content>.three-wrap-ellispis:after {
  background: #f5f5f5;
}

.ellipsis-2-lines{
  /* autoprefixer: off */
    -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.ellipsis-3-lines{
  /* autoprefixer: off */
    -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.ellipsis-4-lines{
  /* autoprefixer: off */
    -webkit-box-orient: vertical;
  /* autoprefixer: on */
  display: -webkit-box;
  -webkit-line-clamp: 4;
  overflow: hidden;
}

.two-wrap-ellipsis {
  margin: 5px 0;
  white-space: normal;
  overflow: hidden;
  width: 100%;
  position: relative;
  line-height: 1.2em;
  max-height: 2.6em;
  margin-right: -1em;
  padding-right: 1em;
  text-align: justify;
}

.two-wrap-ellipsis:before {
  content: '…';
  position: absolute;
  right: 4px;
  top: 1.4em;
  margin-top: -3px;
}

.two-wrap-ellipsis:after {
  content: '';
  position: absolute;
  right: -2px;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: #fafafa;
}

span.mat-option-text {
  margin: 5px 0;
  white-space: normal;
  position: relative;
  line-height: 1.2em;
  max-height: 3.5em;
  margin-right: -1em;
  padding-right: 1em;
  text-align: left;
  text-transform: initial;
}
label.mat-form-field-label{
  text-transform: capitalize;
}

.audit-select .mat-select-value-text{
  text-transform: capitalize;
}

span.mat-option-text:before {
  content: '…';
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
}

span.mat-option-text:after {
  content: '';
  position: absolute;
  right: 0;
  width: 1em;
  height: 1em;
  margin-top: 0.2em;
  background: white;
  z-index: 1;
}

.mat-option-ripple.mat-ripple {
  z-index: 10;
}

.mat-select-panel .mat-option.mat-selected:not(.mat-option-multiple)>span.mat-option-text:after,
.mat-option:hover:not(.mat-option-disabled)>span.mat-option-text:after, .mat-option:focus:not(.mat-option-disabled)>span.mat-option-text:after {
  background: #e0e0e0;
}

.mat-option:hover:not(.mat-option-disabled)>span.mat-option-text:after, .mat-option:focus:not(.mat-option-disabled)>span.mat-option-text:after,
.mat-option.mat-active>span.mat-option-text:after {
  background: #f5f5f5;
}

.mat-option>.mat-ripple {
  border-radius: 0;
}

.mat-nav-list>.mat-list-item>.mat-list-item-content>.mat-ripple {
  border-radius: 0;
}

button.mat-raised-button.mat-primary.cdk-focused.cdk-mouse-focused, button.mat-raised-button.mat-accent.cdk-focused.cdk-mouse-focused,
button.mat-raised-button.mat-warn.cdk-focused.cdk-mouse-focused {
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.2);
}

.meg-list-button.mat-raised-button.mat-warn.cdk-focused.cdk-mouse-focused, .meg-list-button.mat-raised-button.mat-primary.cdk-focused.cdk-mouse-focused,
.meg-list-button.mat-raised-button.mat-accent.cdk-focused.cdk-mouse-focus, button.mat-raised-button:not([class*=mat-elevation-z]) {
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.2);
}

.button-back{
  color: #ffffff;
  background-color: #A6A6A6;
  margin-right: 10px !important;
}

.mat-raised-button.mat-primary[disabled], .mat-raised-button.mat-accent[disabled],
.mat-raised-button.mat-warn[disabled], .mat-raised-button[disabled][disabled],
.mat-fab.mat-primary[disabled], .mat-fab.mat-accent[disabled], .mat-fab.mat-warn[disabled],
.mat-fab[disabled][disabled], .mat-mini-fab.mat-primary[disabled],
.mat-mini-fab.mat-accent[disabled], .mat-mini-fab.mat-warn[disabled], .mat-mini-fab[disabled][disabled] {
  box-shadow: none !important;
  color: white;
}

/* AUTO LAYOUT SECTION */
.meg-table {
  padding: 19px 10px 10px 10px;
  display: table;
  table-layout: fixed;
  text-align: center;
  width: 100%;
}

.meg-row {
  display: table-row;
}

.meg-cell {
  display: table-cell;
  text-align: center;
  padding: 5px;
}

.container-body {
  position: relative;
  margin: auto;
  top: 20px;
  width: 90%;
  text-align: justify;
  padding-bottom: 16px;
}

/* SIDE MENU SECTION */
.mat-list .mat-list-item, .mat-nav-list .mat-list-item, .mat-selection-list .mat-list-item {
  color: #606060;
  font-weight: 300;
}

.subform-card>.mat-nav-list>.mat-list-item {
  margin-bottom: 10px;
}

.mat-list-item-content {
  padding: 0 0 0 16px !important;
}

/* APP VERSION ALT STYLING */
meg-app-version.version-black>p {
  color: #202020;
  text-align: left;
  font-weight: normal;
  font-size: 14px;
  margin-top: 0;
}

/* AUDIT FORM SECTION */
form {
  margin-top: 10px;
}

.mat-form-wrapper {
  margin: auto;
  margin-top: 16px;
  margin-bottom: 80px;
}

.mat-form-wrapper h1 {
  text-align: center;
}

.mat-form-wrapper h2 {
  font-size: 16px;
}

.mat-select-trigger {
  background-color: #fafafa;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-radius: 4px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  margin-bottom: 10px;
  padding: 3px;
}

.audit-select>.mat-select-trigger {
  padding: 8px 3px 3px 3px;
}

.mat-form-field {
  display: block !important;
}

.mat-form-field-infix {
  border-top: none;
  margin-top: 8px;
  width: 100%;
  padding: 0 2px 2px 3px !important;
  height: 25px;
}

.mat-input-infix.mat-form-field-infix {
  width: 265px;
}

.mat-form-field-flex {
  min-height: 44px;
  background-color: white;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.disabled-view>.mat-form-field-wrapper>.mat-form-field-flex {
  pointer-events: none;
  background-color: transparent;
  box-shadow: none;
  border-top: none;
  border-left: none;
}

.date-input-short>meg-custom-date-picker>.mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex {
  background-color: white;
  box-shadow: inset 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.mat-form-field-label {
  left: 5px !important
}

.time-input>meg-time-field>.mat-form-field {
  top: 0;
}

.time-input>meg-time-field>.mat-form-field.ng-touched.mat-form-field-should-float {
  top: 0;
}

.audit-comments>.mat-form-field-wrapper>.mat-form-field-flex {
  border-top: 1px solid #B5D0DA;
  border-left: 1px solid #B5D0DA;
}

textarea {
  resize: none !important;
}

.time-input>.mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex {
  box-shadow: none;
  background-color: transparent;
}

.time-input>.mat-form-field>.mat-form-field-wrapper>.mat-form-field-underline {
  display: block;
}

.time-input>.mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex>.mat-form-field-infix>.mat-form-field-label-wrapper>.mat-form-field-label {
  position: absolute;
  left: 30px;
}

.form-height.ng-untouched.ng-dirty.ng-valid, .form-height.ng-dirty.ng-valid.ng-touched {
  margin: 0 1px 0 1px;
  min-height: 259.5px;
}

#ward-card>.mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex,
#ward-card>meg-searchable-dropdown>.mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex,
#ward-card>.mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex>.mat-form-field-infix>.mat-select>.mat-select-trigger,
#ward-card>meg-searchable-dropdown>.mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex>.mat-form-field-infix>.mat-select>.mat-select-trigger,
#login-card>.form-height>.mat-form-field>.mat-form-field-wrapper>.mat-form-field-flex,
.form-inputs>.mat-form-field-wrapper>.mat-form-field-flex,
.issue-dropdown>.mat-select-trigger {
  background-color: transparent;
  box-shadow: none;
  border-color: transparent;
  margin-bottom: 0;
  border-radius: 0;
  overflow: visible;
}

.form-field {
  margin: 0 2px 0 2px;
}

.meg-group .columns .form-field{
  height: 100%;
}

.mat-radio-button.mat-primary.ng-star-inserted {
  padding: 5px 20px 15px 0;
}

.mat-radio-label-content {
  font-weight: 300;
  overflow-wrap: break-word;
  font-size: 16px;
  position: relative;
  top: 3px;
}

.float-right {
  float: right;
}

.mat-radio-outer-circle {
  background-color: white;
  border: 2px solid #1C9CD8;
  height: 26px !important;
  width: 26px !important;
}

.mat-radio-inner-circle {
  height: 26px !important;
  width: 26px !important;
}

input.mat-input-element {
  position: relative;
  left: 5px;
  top: 5px;
  height: 20px;
  width: 95%;
}

/* AMS AUDIT STYLING */
mat-accordion.mat-accordion.ng-star-inserted {
  position: relative;
  display: block;
  margin: auto;
  width: 90%;
  border-radius: 2px;
  padding: 0 10px 10px 10px ;
  background-color: #fafafa;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-bottom: 2px solid #b0b0b0;
  box-shadow: 8px 8px 24px rgba(0, 0, 0, 0.2);
}

.mat-expansion-panel-body {
  position: relative;
  width: 100%;
  padding: 0 !important;
}

.mat-expansion-panel.ng-star-inserted {
  position: relative;
  margin: auto;
  background-color: transparent;
  width: 100%;
  box-shadow: none !important;
}

.accordion-card>.mat-expansion-panel.ng-star-inserted {
  border-bottom: none;
}

.mat-radio-label {
  white-space: normal !important;
}

.mat-expansion-panel-header-description {
  display: block !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-expansion-panel-body>meg-observation>form>meg-field>.form-field {
  margin: 0;
}

/* MAT ICONS SECTION */
.badge {
  position: absolute;
  left: 50%;
  font-size: 12px;
  line-height: 18px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background-color: #ed3724;
  border: solid 3px #ff5c5c;
  color: #ffffff;
  text-align: center;
}

.badge-large {
  font-size: 13px;
  line-height: 22px;
  width: 30px;
  height: 30px;
  border: 4px solid #ff5c5c;
  top: 8px;
  left: 159px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
}

.badge:empty {
  display: none;
}

.mat-datepicker-toggle>.mat-icon-button {
  right: 0;
  height: 40px;
  width: 40px;
}

.container-child>.mat-datepicker-toggle>.mat-icon-button {
  position: absolute;
  top: -11px;
  left: 3px;
  font-size: 16px;
}

/* ISSUES SECTION */
meg-issues-widget.ng-star-inserted>* {
  margin: 0 16px;
}

.issue-widget-content>.mat-list-item{
  height: auto !important;
}
.issue-widget-content>.mat-list-item>.mat-list-item-content {
  padding: 0 !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}

.mat-error.ng-star-inserted {
  margin: 0 0 10px 0;
}

.error_icon {
  color: red;
}

.mat-expansion-panel-body>meg-observation>form>meg-issues-widget.ng-star-inserted>* {
  margin: 0;
}

.mat-expansion-panel-body>form>meg-issues-widget>.issue-widget-content> {
  margin: 0;
}

/* PREVIEW ISSUES */
.mat-dialog-container.ng-tns-c16-2.ng-trigger.ng-trigger-slideDialog.ng-star-inserted {
  background-color: #fafafa;
  border-top: 1px solid white;
  border-left: 1px solid white;
  box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.3);
  border-radius: 4px !important;
}

.audit-form-dropdown {
  padding: 18px 0 22px 0;
}

.audit-form-dropdown .mat-select-trigger {
  width: 50%;
  border-bottom: solid 1px #b0b0b0;
}

.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}

.mat-expansion-panel {
  background-color: #fafafa;
  border-bottom: solid 2px #b0b0b0;
}

.mat-expansion-panel-header {
  margin: 0;
  padding: 0 !important;
}

.mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled='true']):hover {
  background: transparent;
}


.mat-content {
  width: 100%;
  overflow: visible !important;
}

.mat-expansion-indicator {
  position: absolute;
  right: 8px;
  top: 24px;
  height: 10px;
}

.accordion-card .mat-expansion-indicator {
  top: 11px;
}

.mat-expansion-indicator::after {
  position: relative;
  top: -10px;
}

/* Accordion layout update */
.meg-accordion .mat-expansion-indicator::after{
  border-width: 0 4px 4px 0;
}

mat-dialog-actions.mat-dialog-actions {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 10px;
}

button.dialog-btn-left {
  margin-top: 15px;
  margin-bottom: 0 !important;
}

button.dialog-btn-right {
  position: relative;
  float: right;
  margin-top: 15px;
  margin-bottom: 0!important;
}

.common-issues-content .mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margin{
  left: -10px;
  top: 10px;
  margin: 10px;
}

.mat-checkbox-frame {
  background-color: white !important;
}

.issue-checkbox.mat-checkbox.mat-accent>.mat-checkbox-layout>.mat-checkbox-label {
  position: relative;
  top: -4px;
  left: -30px;
}

.dialog-btn-right>.mat-button-wrapper {
  position: relative;
  left: -5px;
}

.info-container img {
  width: 100% !important;
}

.lone-btn-container {
  position: absolute;
  width: 100%;
  top: 75%;
}

.lone-audit-button.mat-raised-button {
  position: relative;
  display: block;
  margin: auto;
  margin-top: 20px;
}

.meg-list-button>.mat-button-wrapper>.mat-icon {
  position: relative;
  top: 2px;
  left: -5px;
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.lone-audit-button.new-audit-btn>.mat-button-wrapper>.mat-icon {
  top: -2px;
}

.meg-list-button>.mat-button-wrapper {
  position: relative;
  bottom: 2px;
}

.mat-calendar {
  background-color: #fafafa;
  border-top: 1px solid white;
  border-left: 1px solid white;
  box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

.unsubmitted-photos-message {
  position: relative;
  margin: auto;
  width: 95%;
  text-align: center;
}

/* CALENDAR RULES */
.calendar-dialog-content>.mat-list>.calendar-audit>.mat-list-item-content {
  padding: 0 !important;
}

.calendar-dialog-content>.mat-list>.calendar-audit>.mat-list-item-content>.mat-chip {
  border-radius: 4px;
  margin: 0 5px 5px 0;
  float: left;
}

.calendar-dialog-content>.mat-list>.calendar-schedule>.mat-list-item-content>.mat-chip {
  border-radius: 4px;
  margin: 0 5px 5px 0;
  float: left;
}

.mat-chip-list-wrapper {
  margin: 2px 0 0 0 !important;
}

.mat-chip-container .mat-form-field-infix{
  height: auto;
  margin-top:  17px !important;
}
.mat-chip-container .mat-form-field-infix .mat-chip-input{
  border-bottom: 1px dashed #E8E8E8;
  width: calc(100% - 10px);
}
.mat-chip-container .mat-chip-list-wrapper{
  padding-top: 5px;
}
.mat-chip-container .mat-chip-list-wrapper .mat-chip{
  font-size: 10px;
  background-color: #F4F4F4;
  border: 1px solid #E8E8E8;
  padding: 4px 4px 1px 10px;
}
.mat-chip-container .mat-chip-list-wrapper .mat-chip:hover{
  -moz-box-shadow:      0px 0px 4px rgba(0,0,0,.15);
  -webkit-box-shadow:   0px 0px 4px rgba(0,0,0,.15);
  box-shadow:           0px 0px 4px rgba(0,0,0,.15);
  border: 1px solid #FFFFFF;
}
.mat-chip-container .mat-chip-list-wrapper .mat-chip:focus{
  -moz-box-shadow:      2px 2px 4px rgba(0,0,0,.15);
  -webkit-box-shadow:   2px 2px 4px rgba(0,0,0,.15);
  box-shadow:           2px 2px 4px rgba(0,0,0,.15);
  border: 1px solid #d8c31c;
  background-color: #feffeb;
}

.mat-calendar {
  background-color: #fafafa;
  border-top: 1px solid white;
  border-left: 1px solid white;
  box-shadow: 6px 6px 18px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
}

/* TRANSLATABLE IMAGES SECTION */
.img-container {
  position: relative;
  margin: auto;
  max-width: 700px;
  color: white;
}

.img-container:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.message-image {
  position: absolute;
  top: 5px;
  width: 40%;
  right: 5%;
  min-width: 50px;
  max-width: 250px;
}

.speech-bubble {
	position: absolute;
  top: 0;
  right: 45%;
  padding: 20px;
  max-width: 250px;
	background: #1C9CD8;
	border-radius: 50%;
  box-shadow: 10px 10px 30px 5px rgba(0, 0, 0, 0.2);
}

.speech-bubble:after {
	content: '';
	position: absolute;
	right: 8px;
	top: 50%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-left-color: #1C9CD8;
	border-right: 0;
	margin-top: -20px;
	margin-right: -20px;
}

.submitted-bubble {
  min-width: 130px;
}

.larger-text {
  margin-top: 20px !important;
  color: white;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}

.smaller-text {
  font-weight: 300;
  font-size: 16px;
  text-align: center;
}

@media (min-width: 420px) {
  .speech-bubble {
    top: 40px;
  }
}

@media (max-width: 345px) {
  .message-image {
    right: 2px;
  }
}

/* QIP PAGE SECTION */
.issue.mat-list-item>.mat-list-item-content {
  padding: 10px 0 15px 0 !important;
  position: relative;
  margin: auto;
  width: 96%;
}

.mat-content>p {
  position: relative;
  color: #727272;
  left: 0;
  top: 10px;
}

.status>.mat-select-trigger {
  border: none;
}

.recording-status {
  margin-top: 20px;
}


/* ADAPTIVE RULES */
.limit-width {
  width: 80%;
  max-width: 700px;
}

@media (max-height: 569px) {
  .mat-dialog-container {
    max-height: 540px !important;
  }

  .mat-dialog-content.common-issues-content {
    max-height: 75%;
  }
}

@media (max-width: 410px) {
  meg-issues-widget.ng-star-inserted>* {
    margin: 0 5px;
  }
}

@media (min-width: 535px) {
  .details-table {
    margin: 12px 24px 5px 24px !important;
  }
}

@media (max-width: 599px) {
  mat-card.mat-card.meg-saved-card.limit-width {
    padding: 24px;
    width: 84%;
  }

  mat-card.mat-card.settings-card.limit-width {
    padding: 8px;
    width: 94%;
  }

  .mat-card-title {
    font-size: 20px;
  }

  .setting-btn.mat-button {
    padding: 0 0 0 0;
    line-height: normal;
  }
}

/* padding constraints for iOS  */
.ios-container {
  margin-top: constant(safe-area-inset-top) !important; /*for iOS 11.2*/
  margin-top: env(safe-area-inset-top) !important; /*for iOS 11.1*/;
}

simple-snack-bar.mat-simple-snackbar.ng-tns-c26-3.ng-trigger.ng-trigger-contentFade.ng-star-inserted {
  font-size: 16px;
  color: #ff0000;
}

/* ACCORDION CHEVRON ANIMATIONS */
.mat-expansion-panel-header>.mat-expansion-indicator {
  animation: closed 0.3s ease forwards;
}

.mat-expansion-panel-header.mat-expanded>.mat-expansion-indicator,
.mat-expansion-panel-header.mat-expanded:focus>.mat-expansion-indicator {
  animation: opened 0.3s ease forwards;
}

.widget-audio{
  position: relative;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  overflow: auto;
  clear: both;
}
.widget-audio-timer{
  flex-grow: 2;
  position: relative;
  padding: 0px;
  height: 50px;
}
.widget-audio-timer .audio-timer-start{
  position: absolute;
  top: 0;
  left: 0px;
  line-height: 1.75;
  text-align: left;
  font-size: 12px;
}
.widget-audio-timer .audio-timer-remaining{
  position: absolute;
  top: 0;
  right: 0px;
  line-height: 1.75;
  text-align: right;
  font-size: 12px;
  font-weight: bold;
}
.widget-audio-timer .audio-timer-status{
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -12px;
}
.widget-audio-timer .audio-timer-status .mat-icon.mat-accent{
  color: #9DAEB7;
  font-size: 20px;
}
.widget-audio-timer .audio-timer-status .mat-icon.mat-warn{
  color: #FF0000;
  font-size: 20px;
}
.widget-audio-timer .mat-progress-bar{
    -webkit-border-radius: 5px;
       -moz-border-radius: 5px;
        -ms-border-radius: 5px;
            border-radius: 5px;
}
.widget-audio-timer .mat-progress-bar-buffer{
  background-color: #9DAEB7;
}
.widget-audio-timer .mat-progress-bar-fill::after{
  background-color: #ff0000;
}
.widget-audio-controller button{
  transform-origin: 50% 50%;
  transition: all 50ms;
  border: 1px solid #fff;
}
.widget-audio-controller button:active{
  transform: scale(.75,.75);
       -moz-box-shadow: 0 0 10px rgba(0,0,0,.50) inset !important;
    -webkit-box-shadow: 0 0 10px rgba(0,0,0,.50) inset !important;
            box-shadow: 0 0 10px rgba(0,0,0,.50) inset !important;
}
.widget-audio-controller.play button{
  background-color: #00B112;
  margin-right: 10px;
       -moz-box-shadow: 0 0 0 rgba(255,255,255,0);
    -webkit-box-shadow: 0 0 0 rgba(255,255,255,0);
            box-shadow: 0 0 0 rgba(255,255,255,0);
}
.widget-audio-controller.play button.pause{
  background-color: #9DAEB7;
  border: 1px solid #fff;
}
.widget-audio-controller.record button{
  margin-left: 10px;
       -moz-box-shadow: 0 0 0 rgba(255,255,255,0);
    -webkit-box-shadow: 0 0 0 rgba(255,255,255,0);
            box-shadow: 0 0 0 rgba(255,255,255,0);
}
.widget-audio-controller.record button.mic{
  border: 2px solid #000;
  background-color: transparent;
  color: #000;
}
.widget-audio-controller.record button.rec{
  background-color: #FF0000;
}

.form-dropdown-3-lines{
  max-height: 150px !important;
}

.save-progress-btn {
  margin-left: 10px !important;
}

.inline-chip .mat-chip-list-wrapper{
  display: inline;
  position: relative;
}
.inline-chip .mat-chip-list-wrapper .mat-warn{
  padding: 0px 5px;
  background-color: red;
  color: white;
  font-size: 10px;
  position: absolute;
  top: -2px;
}

/* -- 2022 Update form view Header-- */
.mat-form-wrapper .mat-form-header{
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.mat-form-wrapper .mat-form-header .subtitle.v2{
  font-size: 24px;
  font-weight: bold;
  text-align: left;
  color: #1C9CD8;
  margin-bottom: 0;
}
.mat-form-wrapper .mat-form-header .subtitle.v2 span{
  font-weight: 300;
  color: #000000;
}
.mat-form-wrapper .mat-form-header .field-info{
  background-color: #fff;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  padding: 3px;
  font-size:  12px;
  text-transform: uppercase;
  white-space:  nowrap;
}
.mat-form-wrapper .mat-form-header .field-info.required::before{
  content: ':';
  display: inline-block;
  height: 100%;
  width: 5px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #EB3737;
  color: #EB3737;
  margin-right: 5px;
}

/* -- 2022 Update form view -- */
.mat-form-wrapper{
  padding: 5px 15px;
}

.mat-form-wrapper .mat-form-information {
  margin: 0 0 10px 0 !important;
  padding: 0 10px !important;
  width: 100% !important;
  border-radius: 4px !important
}

.mat-form-wrapper .mat-form-information .mat-expansion-panel {
  border-bottom: none;
  background-color: #fafafa;
  padding: 12px 0 !important;
}

.mat-form-wrapper .mat-form-information .mat-expansion-panel .mat-expansion-panel-header {
  height: auto !important;
}

.mat-form-wrapper .mat-form-information .mat-content {
  flex-wrap: nowrap;
}

.mat-form-wrapper .mat-form-information .mat-expansion-indicator {
  position: static;
  right: auto;
  top: auto;
  height: 6px;
}

.mat-form-wrapper .mat-form-information .mat-list {
  padding-top: 12px;
}
.mat-form-wrapper .mat-form-information .mat-list-item {
  cursor: pointer;
}

.mat-form-wrapper .mat-form-information .mat-list-item:first-child {
    border-top: 1px solid #cecece;
}

.mat-form-wrapper .mat-form-information .mat-list-item:not(:last-child) {
    border-bottom: 1px solid #cecece;
}

.mat-form-wrapper .mat-form-information .mat-list-item.document-item {
  padding: 10px 0;
  height: auto !important;
}

.mat-form-wrapper .mat-form-information .mat-list-item .document-item-wrapper {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
}

.mat-form-wrapper .mat-form-information .mat-list-item .document-item-img {
  min-width: 75px;
}

.mat-form-wrapper .mat-form-information .mat-list-item .document-item-content {
  flex-grow: 1;
  flex-direction: column;
  display: flex;
  padding: 0 15px
}

.mat-form-wrapper .mat-form-information .mat-list-item .document-item-title {
  font-size: 20px;
  font-weight: bold;
}

.mat-form-wrapper .mat-form-information .mat-list-item .document-item-footer .mat-icon {
  vertical-align: middle;
}
.mat-form-wrapper .mat-form-information .mat-list-item .document-item-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #1C9CD8;
}

.mat-form-wrapper .mat-form-information .mat-list-item-content {
    padding-left: 0 !important;
}

.form-field .mat-form-field-wrapper{
  padding-bottom: 0px;
}

.form-field .mat-form-field-flex{
  min-height: 45px;
  -webkit-border-radius:  5px;
  -moz-border-radius:     5px;
  border-radius:          5px;
  -moz-box-shadow:        0px 0px 0px rgba(0,0,0,0) !important;
  -webkit-box-shadow:     0px 0px 0px rgba(0,0,0,0) !important;
  box-shadow:             0px 0px 0px rgba(0,0,0,0) !important;
  border: 2px solid #7CCCF2;
}
.form-field .mat-form-field-flex .mat-form-field-infix{
  margin-top: 5px;
  margin-bottom: 5px;
}
.form-field .mat-radio-outer-circle{
  border: 2px solid #7CCCF2 !important;
}
.form-field.required .mat-radio-outer-circle{
  border: 2px solid #EB3737 !important;
}
.form-field .mat-checkbox-frame{
  border: 2px solid #7CCCF2 !important;
}
.form-field.required .mat-checkbox-frame{
  border: 2px solid #EB3737 !important;
}
.form-field meg-timer{
  padding: 0;
  margin: 0;
}
.form-field meg-timer .timer-btn{
  margin-left: -16px;
}
.mat-form-wrapper .form-field .mat-select-trigger{
  -webkit-border-radius:  5px;
  -moz-border-radius:     5px;
  border-radius:          5px;
  -moz-box-shadow:        0px 0px 0px rgba(0,0,0,0);
  -webkit-box-shadow:     0px 0px 0px rgba(0,0,0,0);
  box-shadow:             0px 0px 0px rgba(0,0,0,0);
  border: 2px solid #7CCCF2;
  height: 45px;
  margin-bottom: 2px;
}
.form-field.required .mat-form-field-flex, .form-field.required .mat-select-trigger{
  border: 2px solid #EB3737;
}
.mat-form-wrapper .form-field .mat-select-trigger .mat-select-value{
  vertical-align: middle;
  padding-left: 10px;
}
.form-field .mat-form-field-flex .mat-icon-button{
  margin-top: -3px;
}
.mat-form-wrapper .form-field .clear-button{
}
.mat-form-wrapper .form-field .mat-error{
  display: inline-block;
  background-color: #EB3737;
  color: #ffffff;
  padding: 2px 5px;
  margin: 0;
  font-size:  12px;
  font-weight: bold;
  -webkit-border-radius:  5px;
  -moz-border-radius:     5px;
  border-radius:          5px;
}

@media screen   and (max-width : 700px){
  .mat-form-wrapper{
    padding: 5px;
  }
}

/* -- Action Groups -- */
.action-group{
  position: relative;
  background-color: #fff;
  padding: 5px;
  -webkit-border-radius:  5px;
  -moz-border-radius:     5px;
  border-radius:          5px;
  -moz-box-shadow:      0 0px 15px 3px rgba(0,0,0,.25);
  -webkit-box-shadow:   0 0px 15px 3px rgba(0,0,0,.25);
  box-shadow:           0 0px 15px 3px rgba(0,0,0,.25);
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: -70px;
  margin-top:  20px;
}
.action-group button{
  vertical-align: middle;
  margin-top: 0;
  margin-bottom: 0;
  position: static;
  -moz-box-shadow:      0 0 0 rgba(0,0,0,0) !important;
  -webkit-box-shadow:   0 0 0 rgba(0,0,0,0) !important;
  box-shadow:           0 0 0 rgba(0,0,0,0) !important;
}
.action-group .observation-qip-status{
  padding: 0 5px;
}
.action-group .btn-highlight-soft{
  margin: 0 10px;
  border: 1px solid #D3D3D3;
  margin: 0 10px 1px 10px;
  min-height: 35px;
  line-height: 1;
  position: relative;
}
.action-group.save-btn-right{
  float: left;
  width: calc(100% - 140px);
}
.action-group.sticky{
  position: sticky;
  bottom: 10px;
  float: right;
  width: 130px;
}
.action-group.sticky button{
  margin: 0 !important;
  width: 100%;
}
@media screen and (min-width : 700px)  and (max-width : 900px) {
  .action-group{
    align-items: flex-end;
  }
  .action-group .meg-issues-widget .observation-qip-status{
    margin: 0;
    padding: 0 5px;
    display: block;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    align-items: flex-end;
  }
  .action-group .meg-issues-widget .observation-qip-status strong{
    flex-grow: 2;
    width: 100%;
    margin-bottom: 5px;
  }
  .action-group .meg-issues-widget .observation-qip-status .btn-highlight-soft{
    white-space: pre-wrap;
    line-height: 1;
    padding: 5px;
    text-align: left;
    margin: 0 5px 2px 0;
    width: calc(100% - 120px);
    height: 34px;
  }
  .action-group .meg-issues-widget .observation-qip-status .issue-button-right.mat-raised-button.mat-primary{
    padding: 0 5px;
    width: 110px;
  }
}
@media screen   and (max-width : 700px) {
  .qip-issues-list-footer{
    width: 90%;
  }
  .action-group{
    position: relative;
    flex-direction: row;
    flex-wrap: wrap;
    padding:  5px;
    margin:  10px 0px -70px 0px;
  }
  .action-group .meg-issues-widget{
    order: 1;
    border-bottom: 1px solid #cecece;
    width: 100%;
    flex-grow: 2;
    margin-bottom: 10px;
  }
  .action-group .meg-issues-widget .observation-qip-status{
    margin: 0;
    padding: 0 0 10px 0;
    display: block;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    align-items: flex-start;
  }
  .action-group .meg-issues-widget .observation-qip-status strong{
    flex-grow: 2;
    width: 100%;
    margin-bottom: 5px;
  }
  .action-group .meg-issues-widget .observation-qip-status .btn-highlight-soft{
    white-space: pre-wrap;
    line-height: 1;
    padding: 5px;
    text-align: left;
    margin: auto 5px auto 0;
    width: calc(100% - 120px);
  }
  .action-group .meg-issues-widget .observation-qip-status .issue-button-right.mat-raised-button.mat-primary{
    padding: 0 5px;
    width: 110px;
    max-width:  110px;
  }
  .action-group .button-order-1{
    order: 1;
  }
  .action-group .button-order-2{
    order: 2;
  }
  .action-group .button-order-3{
    order: 3;
  }
  .action-group.save-btn-right{
    float: none;
    width: 100%;
    margin: 85px 0px -70px 0px;
  }
  .action-group.sticky{
    float: none;
    width: 100%;
  }
}

/* -- Issues Dialog Containers -- */
.mat-dialog-container{
  padding:  20px !important;
  border-radius: 10px !important;
  background-color: #ffffff;
}
.qip-issues-list-group .issue-expansion .mat-content{
  flex-wrap: nowrap;
}
.qip-issues-list-group .issue-expansion .mat-expansion-indicator{
  position: static;
  right: auto;
  top: auto;
  height: 12px;
}
.qip-issues-list-group .issue-expansion .mat-expansion-panel-header-title .issue-checkbox .mat-checkbox-layout .mat-checkbox-inner-container{
  left: auto;
  top: auto;
  margin: auto;
}
.qip-issues-list-group .mat-expansion-panel-body .mat-form-field .mat-form-field-flex, .qip-create-issues-dialog .mat-form-field .mat-form-field-flex{
  padding: 0 5px;
  border: 1px solid #CCCCCC;
  -webkit-border-radius:  5px;
  -moz-border-radius:     5px;
  border-radius:          5px;
  -moz-box-shadow:        0px 0px 0px rgba(0,0,0,0.0);
  -webkit-box-shadow:     0px 0px 0px rgba(0,0,0,0.0);
  box-shadow:             0px 0px 0px rgba(0,0,0,0.0);
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped, .qip-create-issues-dialog .issue-form-grouped{
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.qip-issues-list-group .mat-expansion-panel-body label, .custom-issue-content label{
  color: #7F898D;
  font-size: 12px;
  margin-bottom: 5px;
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column.wide, .qip-create-issues-dialog .issue-form-grouped .flexed-column.wide{
  flex-grow: 2;
  margin-left: 10px;
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-row.wide, .qip-create-issues-dialog .issue-form-grouped .flexed-row.wide{
  flex-grow: 2;
  margin: 0;
  width: 100%;
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column.wide.col-2 .column-label, .qip-create-issues-dialog .issue-form-grouped .flexed-column.wide.col-2 .column-label{
  width: 100%;
  display: block;
  margin-bottom: 0;
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column.wide.col-2 .audit-form-dropdown, .qip-create-issues-dialog .issue-form-grouped .flexed-column.wide.col-2 .audit-form-dropdown{
  display: inline-block;
  width: 50%;
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column.wide.col-2 .audit-form-dropdown .mat-select-trigger, .qip-create-issues-dialog .issue-form-grouped .flexed-column.wide.col-2 .audit-form-dropdown .mat-select-trigger{
  width: 100%;
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .mat-form-field-wrapper, .qip-create-issues-dialog .issue-form-grouped .mat-form-field-wrapper{
  padding-bottom: 0;
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column .audit-form-dropdown, .qip-create-issues-dialog .issue-form-grouped .flexed-column .audit-form-dropdown{
  margin: 0;
  padding: 0;
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column .mat-select, .qip-create-issues-dialog .issue-form-grouped .mat-select{
  margin-bottom: 0;
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column .mat-select-trigger, .qip-create-issues-dialog .issue-form-grouped .mat-select-trigger{
  background-color: #FFFFFF;
  border: 1px solid #cccccc;
  height: 44px;
  padding: 5px;
  -webkit-border-radius:  5px;
  -moz-border-radius:     5px;
  border-radius:          5px;
  margin-bottom: 0;
  -moz-box-shadow:        0px 0px 0px rgba(0,0,0,0);
  -webkit-box-shadow:     0px 0px 0px rgba(0,0,0,0);
  box-shadow:             0px 0px 0px rgba(0,0,0,0);
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column .mat-select-trigger .mat-select-value, .qip-create-issues-dialog .issue-form-grouped .mat-select-trigger .mat-select-value{
  vertical-align: middle;
}
.qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column .issue-email, .qip-create-issues-dialog .issue-form-grouped .flexed-column .issue-email{
  top: auto;
  position: static;
  margin-top: 10px;
}
.qip-issues-list-group.mat-expanded .mat-expansion-panel-content{
  background-color: #EBF8FF;
  -webkit-border-radius:  5px;
  -moz-border-radius:     5px;
  border-radius:          5px;
  padding: 16px;
  margin: 10px -15px -5px -15px;
}
@media screen and (max-width : 700px) {
  .mat-dialog-container{
    max-width: 90%;
    min-width: 90vw;
    height: auto !important;
  }
  .qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped{
    flex-direction: column;
  }
  .qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column, .qip-create-issues-dialog .issue-form-grouped .flexed-column{
    flex-grow: 2;
    width: 100%;
  }
  .qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column.wide, .qip-create-issues-dialog .issue-form-grouped .flexed-column.wide{
    margin-left: 0
  }
  .qip-issues-list-group .mat-expansion-panel-body .issue-form-grouped .flexed-column .issue-email, .qip-create-issues-dialog .issue-form-grouped .flexed-column .issue-email{
    width: 100%;
    display: block;
    top: auto;
    height: auto;
    padding: 20px 0 10px 0;
  }
}
@media screen and (min-width : 700px) {
  .mat-dialog-container{
    width: 75vw !important;
    max-width: 1000px;
    height: auto !important;
    /*min-height: 600px !important;*/
  }
}

/* -- Accordions-- */
.accordion-card .mat-expansion-panel-content.mat-expanded{
  margin-top: 10px;
  transition: 30ms;
}
.accordion-card .mat-expansion-panel-content.mat-expanded .form-field.none{
  margin-top: 0;
}
.accordion-card .mat-expansion-panel-content.mat-expanded .form-field.none h2{
  margin: 0 0 10px 0;
}
.accordion-card .mat-expansion-panel-content.mat-expanded .action-group{
  position: static;
  left: auto;
  right: auto;
  bottom: auto;
  width: 100%;
  margin: auto;
  padding: 10px 0;
  background-color: transparent;
  -moz-box-shadow:        0px 0px 0px rgba(0,0,0,0);
  -webkit-box-shadow:     0px 0px 0px rgba(0,0,0,0);
  box-shadow:             0px 0px 0px rgba(0,0,0,0);
}
.accordion-card .mat-expansion-panel-content.mat-expanded .action-group .meg-issues-widget{
  margin: auto;
}
/* -- Field Comments controller -- */
.answer-comments-enabled{
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: end;
  clear: both;
}
.answer-comments-enabled > .ng-star-inserted{
  width: calc(100% - 55px);
}
.comments-box .mat-form-field-flex{
  border: 1px solid #cccccc;
  margin-top: 5px;
  min-height: 100px;
}

/* -- Zoom Level Updates -- */
@media screen and (max-width : 500px) {
  /* -- Zoom Level 2-- */
  body.zoom2 .mat-dialog-container{
    min-width: 250px;
    width: calc(100vw - 20px);
  }
  body.zoom2 .mat-dialog-container .qip-issues-list-header .mat-raised-button{
    padding: 5px 5px 7px 5px !important;
    line-height: 1;
  }
  body.zoom2 .mat-dialog-container .mat-raised-button{
    padding: 5px !important;
    line-height: 1;
    height: 30px;
  }
  body.zoom2 .mat-dialog-container .qip-issues-list-footer{
    height: 40px;
  }
  body.zoom2 .mat-dialog-container .common-issues-content{
    max-height: calc(100% - 90px);
    max-height: 55vh;
  }
  body.zoom2 .custom-issue-content{
      max-height: calc(100% - 90px);
      max-height: 55vh;
  }
  /* -- Zoom Level 3-- */
  body.zoom3 .mat-dialog-container{
    min-width: 220px;
    width: calc(100vw - 20px);
  }
  body.zoom3 .mat-dialog-container .qip-issues-list-header .mat-raised-button{
    padding: 5px 5px 7px 5px !important;
    line-height: 1;
  }
  body.zoom3 .mat-dialog-container .mat-raised-button{
    padding: 5px !important;
    line-height: 1;
    height: 30px;
  }
  body.zoom3 .mat-dialog-container .qip-issues-list-footer{
    height: 40px;
  }
  body.zoom3 .mat-dialog-container .common-issues-content{
    max-height: calc(100% - 90px);
    max-height: 45vh;
  }
  body.zoom3 .custom-issue-content{
      max-height: calc(100% - 90px);
      max-height: 45vh;
  }
  /* -- Zoom Level 5-- */
  body.zoom5 .mat-dialog-container{
    min-width: 220px;
    width: calc(100vw - 20px);
  }
  body.zoom5 .mat-dialog-container .qip-issues-list-header .mat-raised-button{
    padding: 5px 5px 7px 5px !important;
    line-height: 1;
  }
  body.zoom5 .mat-dialog-container .mat-raised-button{
    padding: 5px !important;
    line-height: 1;
    height: 30px;
    transform: scale(.75);
  }
  body.zoom5 .mat-dialog-container .qip-issues-list-footer{
    height: 40px;
  }
  body.zoom5 .mat-dialog-container .common-issues-content{
    max-height: calc(100% - 90px);
    max-height: 35vh;
  }
  body.zoom5 .custom-issue-content{
      max-height: calc(100% - 90px);
      max-height: 35vh;
  }
}
@media screen and (min-width : 500px) and (max-width : 700px) {
  body.zoom2 .mat-dialog-container{
    min-width: 250px;
    width: calc(100vw - 20px);
  }
  body.zoom3 .mat-dialog-container{
    min-width: 220px;
    width: calc(100vw - 20px);
  }
  body.zoom5 .mat-dialog-container{
    min-width: 220px;
    width: calc(100vw - 20px);
  }
}

.mat-submission-picker-card {
  margin: 0.5rem 0;
  padding: 12px !important;
  background-color: #f5f5f5;
  box-shadow: 0 0 0 rgba(0,0,0,0) !important;
}

.mat-submission-picker-card .mat-card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mat-submission-picker-dialog-content.loading {
  overflow-y: hidden;
  padding: 3rem;
}

.mat-submission-picker-card .mat-submission-picker-type {
  padding-right: 1rem;
  width: 100px;
  text-align: left;
}

.mat-submission-picker-card .mat-submission-picker-timestamp {
  display: block;
  width: fit-content;
  font-size: 10px;
  padding: 4px 8px;
  margin-top: -13px;
  -webkit-border-bottom-right-radius: 4px;
  -webkit-border-bottom-left-radius: 4px;
  -moz-border-radius-bottomright: 4px;
  -moz-border-radius-bottomleft: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.mat-submission-picker-card .mat-submission-picker-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}
.mat-submission-picker-card .form-info{
  margin: 0;
  width: 100%;
}
.mat-submission-picker-card .form-details{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.mat-submission-picker-card .form-container {
  display: block;
  width: 50%;
}

.mat-submission-picker-card .form-name {
  display: block;
  color: #1C9CD8;
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}

.mat-submission-picker-card .form-preview {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
}

.mat-submission-picker-card .ward-name {
  margin-top: 5px;
  font-size: 14px;
  width: 50%;
  padding-left: 10px;
}
.mat-submission-picker-card .mat-button {
  color: #1C9CD8;
  margin: 0;
  min-width: auto;
  padding: 0 8px;
}
@media (max-width: 535px) {
  .mat-submission-picker-card .form-details{
    flex-direction: column;
  }
  .mat-submission-picker-card .form-name {
    width: 100%;
  }
  .mat-submission-picker-card .ward-name {
    width: 100%;
    padding-left:0;
  }
}

/* Misc Items */
.login-version{
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}