@import '~@angular/material/theming';
@include mat-core();

//Tool used to generate the color palette
//http://mcg.mbitson.com/#!?mcgpalette0=%23009ee2&themename=meg

/* For use in src/lib/core/theming/_palette.scss */
$meg-primary: (
  50 : #e0f3fc,
  100 : #b3e2f6,
  200 : #80cff1,
  300 : #4dbbeb,
  400 : #26ade6,
  500 : #1C9CD8,
  600 : #0096df,
  700 : #008cda,
  800 : #0082d6,
  900 : #0070cf,
  A100 : #f6fbff,
  A200 : #c3e0ff,
  A400 : #90c6ff,
  A700 : #77b9ff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$meg-accent: (
  50 : #e3f9e0,
  100 : #b9f0b3,
  200 : #8ae780,
  300 : #5bdd4d,
  400 : #37d526,
  500 : #80BF31,
  600 : #12c900,
  700 : #0ec200,
  800 : #0bbc00,
  900 : #06b000,
  A100 : #daffda,
  A200 : #a7ffa7,
  A400 : #75ff74,
  A700 : #5bff5a,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #000000,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

$meg-warn: (
  50 : #ffe0e0,
  100 : #ffb3b3,
  200 : #ff8080,
  300 : #ff4d4d,
  400 : #ff2626,
  500 : #EA3D2B,
  600 : #EA3D2B,
  700 : #EA3D2B,
  800 : #EA3D2B,
  900 : #EA3D2B,
  A100 : #ffffff,
  A200 : #fff2f2,
  A400 : #ffbfbf,
  A700 : #ffa6a6,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #000000,
    A700 : #000000,
  )
);

//the first two are the custom palettes we declared above
$primary: mat-palette($meg-primary);
$accent: mat-palette($meg-accent);
$warn: mat-palette($meg-warn);

$theme: mat-light-theme($primary, $accent, $warn);
@include angular-material-theme($theme);

@mixin mat-ripple-theme($theme) {
  .mat-radio-ripple.mat-ripple {
    background-color: rgba(0, 158, 226, .4);
  }
}
